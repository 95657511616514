import OtherScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/other-scars/OtherScarsTemplate';
import { MISHAP_SCAR_SIZE, ACNE_SCAR_CAUSE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/OtherScars/OtherScarsTemplate'
};

const createStory = props => () => ({
  components: { OtherScarsTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <other-scars-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  mishapScarsSize: MISHAP_SCAR_SIZE.LARGE_COVERAGE,
  acneScarCauses: [ACNE_SCAR_CAUSE.SURGERY],
  title: 'My surgery Scars'
});
